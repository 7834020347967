import _ from "lodash";
import PanelStory from '../../components/Stories/PanelStory';
import Slider from "react-slick";
import React from 'react';

const EmptyStoriesView = () => {
  return (
    <h4 className='text-center'>This category has no stories yet</h4>
  )
}

const StoriesView = ({stories}) => {
  if (_.isEmpty(stories)) {
    return <EmptyStoriesView />;
  } else {
    return (      
      <StoriesGroup stories={stories} />
    )
  }
};

const StoriesGroup = ({stories}) => {
  const settings = {
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {_.map(stories, (story) => (
        <PanelStory story={story} />
      ))}
    </Slider>
  )
}

const StoriesPanel = ({stories, section, subtitle}) => {
  return (
    <section className="text-gray-600 body-font bg-center bg-no-repeat bg-center bg-cover w-full" style={{backgroundImage: `url(/images/gradient-strips.png)`}}>
      <div className="container px-5 py-24 mx-auto">
        {section && <h2 className="text-6xl title-font text-left mb-1 uppercase text-blue stories-panel-header">{section}</h2>}
        {!subtitle && <h3 className="title-font sub-header text-1xl font-medium text-gray-heading stories-panel-subheader">Recent Stories</h3>}
          <StoriesView stories={stories} />
      </div>
    </section>
  )
}

export default StoriesPanel;