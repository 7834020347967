import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import UnauthenticatedView from "../components/API/UnauthenticatedView";
import Link from "next/link";
import Head from "next/head";
import Image from "next/image";
import { getCdnUrl } from "../lib/env";
import styles from "../styles/Home.module.css";
import Header from "../components/Header/Header";
import MainHero from "../components/Hero/MainHero";
import StoriesPanel from "../components/Stories/StoriesPanel";
import TitleTextButtonLeft from "../components/CTA/TitleTextButtonLeft";
import TitleTextButtonRight from "../components/CTA/TitleTextButtonRight";
import VideoHero from "../components/Hero/VideoHero";
import TitleTextButtonCenter from "../components/CTA/TitleTextButtonCenter";
import FooterPanel from "../components/Footer/FooterPanel";
import Footer from "../components/Footer/Footer";
import { fetchAPI } from "../lib/api";

function SignInButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance } = useMsal();

  return <button onClick={() => instance.loginRedirect()}>Sign In</button>;
}

function WelcomeUser() {
  const { accounts } = useMsal();
  const username = accounts[0].username;
  const accountID = accounts[0].localAccountId;

  return `Welcome, ${username}. Your Azure ID is ${accountID}`;
}

export default function Home({ homepageResponse, storiesResponse }) {
  const content = homepageResponse.data.attributes.Content;
  const stories = storiesResponse.data;

  const contentLoader = (name, id) => {
    var contentObject = new Object();
    contentObject["name"] = name;
    contentObject["id"] = id;
    contentObject["content"] = content.filter((component) => {
      return component.__component === name && component.id === id;
    });
    return contentObject;
  };

  const storiesByFeatured = stories.filter((story) => {
    return story.attributes.Featured === true;
  });

  const storiesByStoryline = (storyline) => {
    return stories.filter((story) => {
      return story.attributes.storylines.data[0]?.attributes.Name === storyline;
    });
  };

  return (
    <div className="page-wrap">
      <Head>
        <title>iStory</title>
        <meta name="description" content="The Home of our Protiviti Story" />
        <link rel="icon" href={getCdnUrl("/favicon.ico")} />
      </Head>

      <AuthenticatedTemplate>
        <Header page="home" />

        <main className={styles.main}>
          <MainHero contentObj={contentLoader("hero.main-hero", 1)} />
          <StoriesPanel
            stories={storiesByFeatured}
            section="Featured"
            subtitle="false"
          />
          <TitleTextButtonRight
            contentObj={contentLoader("cta.title-text-button-right", 1)}
          />
          <VideoHero contentObj={contentLoader("hero.video-hero", 2)} />
          <StoriesPanel
            stories={storiesByStoryline("Different by Design")}
            section="Different by Design"
          />
          <VideoHero contentObj={contentLoader("hero.video-hero", 1)} />
          <StoriesPanel
            stories={storiesByStoryline("The Protiviti Family")}
            section="The Protiviti Family"
          />
          <TitleTextButtonLeft
            contentObj={contentLoader("cta.title-text-button-left", 2)}
          />
          <StoriesPanel
            stories={storiesByStoryline("Earning Trust")}
            section="Earning Trust"
          />
          <TitleTextButtonLeft
            contentObj={contentLoader("cta.title-text-button-left", 1)}
          />
          <StoriesPanel
            stories={storiesByStoryline("Always Looking Ahead")}
            section="Always Looking Ahead"
          />
          <TitleTextButtonLeft
            contentObj={contentLoader("cta.title-text-button-left", 3)}
          />
          <TitleTextButtonCenter
            contentObj={contentLoader("cta.title-text-button-center", 3)}
          />
          <FooterPanel />
          {/* <h1 className={styles.title}>
            <WelcomeUser />
          </h1> */}
        </main>

        <Footer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedView />
      </UnauthenticatedTemplate>
    </div>
  );
}

export async function getStaticProps() {
  // Run API calls in parallel
  const homepageResponse = await fetchAPI(
    "/homepage?populate[Content][populate]=%2A"
  );
  const storiesResponse = await fetchAPI("/stories?sort[0]=createdAt%3Adesc&populate=*");

  return {
    props: { homepageResponse, storiesResponse },
    revalidate: 1,
  };
}
