import Image from 'next/image';
import Link from 'next/link';

const PanelStory = ({story}) => {
  var storylines = story.attributes.storylines.data;
  var date = story.attributes.publishedAt;
  var title = story.attributes.Title;
  var excerpt = story.attributes.Excerpt;
  var slug = story.attributes.Slug;
  var thumbnail = story.attributes.Thumbnail.data?.attributes.url;
  
  return (
    <div className="p-4 home-story">
      <Link href={`stories/${slug}`} props={storylines, date, title, excerpt, slug} passHref>
        <div className="h-full wrapper bg-gray-100 px-8 pt-12 pb-12 overflow-hidden text-left relative">
          <div className="lg:h-48 md:h-36 mb-3 relative unset-img">
            <Image className="custom-img" alt="stories" src={thumbnail ? thumbnail : '/images/720x400.png'} layout="fill" />
          </div>
          <h3 className="title-font text-dark-blue sm:text-1xl text-xl font-medium text-gray-900 mb-3">{title}</h3>
          <p className="leading-relaxed mb-9">{excerpt}</p>
          <button className="flex w-full content-between text-white btn-orange border-0 btn-padding focus:outline-none text-lg w-svg">See More 
            <svg width="25.032" height="21.342" viewBox="0 0 25.032 21.342">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_29" data-name="Rectangle 29" width="21.342" height="25.032" fill="#fff"/>
                </clipPath>
              </defs>
              <g id="Group_49" data-name="Group 49" transform="translate(25.032) rotate(90)">
                <path id="Path_85" data-name="Path 85" d="M10.661,0,0,10.622l.824.827,9.254-9.221L10.1,22.51h1.167L11.246,2.234l9.271,9.259.825-.826Z" transform="translate(0)" fill="#fff"/>
                <g id="Group_34" data-name="Group 34" transform="translate(0 0)">
                  <g id="Group_33" data-name="Group 33" clipPath="url(#clip-path)">
                    <path id="Path_86" data-name="Path 86" d="M42.483,100.261a.841.841,0,1,0,1.189,0,.84.84,0,0,0-1.189,0" transform="translate(-32.376 -76.664)" fill="#fff"/>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </Link>
    </div>
  )
}

export default PanelStory;